import { cn } from '@/lib/utils';
import { cva } from 'class-variance-authority';
import React from 'react';

const textVariants = cva('w-fit font-body text-eis-black', {
	variants: {
		type: {
			p: 'text-base',
			span: 'text-base',
			small: 'text-14xs leading-eis-1.29',
		},
		variant: {
			normal: 'font-normal',
			bold: 'font-bold',
			tag: 'tracking-eis-2 uppercase',
			// italic: 'italic',
			info: 'text-notificationsInfo',
			warning: 'text-notificationsWarning',
			error: 'text-notificationsError',
			success: 'text-notificationsSuccess',
		},
	},

	defaultVariants: {
		type: 'p',
		variant: 'normal',
	},
});

const Text = React.forwardRef(({ type, variant, className, ...props }, ref) => {
	return React.createElement(type, {
		className: cn(textVariants({ type, variant, className })),
		...props,
	});
});
Text.displayName = 'Text';

export { Text, textVariants };
